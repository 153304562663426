// @FIXME: need to fix method sort
// @FIXME: need to determine what state is not being used
// @FIXME: need to use better key values
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/sort-comp */
/* eslint-disable array-callback-return */
// @flow
import React, { Component } from 'react'
import styled from '@emotion/styled'
import get from 'lodash/get'
import { Label } from '@mlcl-digital/mlcl-design'

// redux.
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createEvent } from '../../../../../../utils/telemetry'
import { actionCreators } from '../../../../../../actions'

// styles.
import styles from './panels.styles'

// components.
import Button from '../../../../../atoms/Button'
import Checkbox from '../../../../../atoms/Checkbox'
import Heading from '../../../../../atoms/Heading'
import Input from '../../../../../atoms/Input'
import Select from '../../../../../atoms/Select'
import ToggleGroup from '../../../../../molecules/ToggleGroup'

// utils
import { ignoreColumns } from '../../../../../../utils/stylesUtils'
import { getBancsCustomerNumber } from '../../../../../../utils/cookieUtils'
import { getSelectedValue } from '../../../../../../utils/formUtils'
import { isNonSuperProduct } from '../../../../../../utils/quoteUtils'
import { isFeatureEnabled } from '../../../../../../utils/featureToggling'

// constants
import {
  DEFERMENT_PERIOD,
  MAX_RANGE,
  MIN_RANGE,
  PREMIUM_STRUCTURE,
  COVER_STYLE,
  TPD_DEFINITION,
  READONLY,
  RENEWAL_REMUNERATION_LEVEL_VALUE,
  RENEWAL_REMUNERATION_LEVEL_CODE,
  RENEWAL_REMUNERATION_INPUT_CODE,
  INITIAL_REMUNERATION_INPUT_CODE,
  TPD_DEFINITION_OWN,
  NEW_STEPPED_LABEL,
} from '../../../../../../constants/adviser'

type PanelsProps = {
  // an object holding the benefit info passed
  benefits: {},
  // an object holding all the benefits for one product code
  productBenefitList: {},
  // an object container labels from sitecore
  fields: {},
  // an string getting product Id
  productId: string,
  // An object containing action creator functions.
  actions: Object<Function>,
  // function to persist chosen benefit type
  setInputBenefitType: Function,
  // updated persisted input
  updatedInputBenefiType: string,
  // function to persist chosen benefit type features
  setBenefitTypeAllowedFeature: Function,
  // updated persisted input
  updatedBenefitTypeAllowableFeature: string,
  // form post validation
  isError: boolean,
  // preference post api call
  isPreferencePostLoading: boolean,
  // feature toggling flag
  isCaliRelabel: boolean,
  setFormTouched: Function,
  productData: Array<Object>,
}

const FullWidthToggleGroup = ignoreColumns(ToggleGroup)
const FullWidthSelect = ignoreColumns(Select)
const Container = styled('div')(styles.container)
const ContainerToggleGroup = styled('div')(styles.containerToggleGroup)
const Footer = styled('div')(styles.footer)
const Form = styled('form')(styles.form)
const InnerHolder = styled('div')(styles.innerHolder)
const Title = styled(Heading)(styles.title)
const PanelLabel = styled('span')(styles.panelLabel)
const Text = styled('div')(styles.text)

export class Panels extends Component<PanelsProps> {
  constructor(props) {
    super(props)
    const {
      benefits,
      productBenefitList,
      updatedBenefitTypeAllowableFeature,
      updatedInputBenefiType,
    } = this.props
    const switchedBenefit = this.switchBenefitByTypeSelection(
      benefits,
      productBenefitList,
      updatedInputBenefiType
    )
    const {
      allowableFeatures,
      remuneration,
      allowableBenefitPeriod,
      allowableDefermentPeriod,
      displayOnChooseCover,
    } = switchedBenefit

    this.handleCheckboxList = this.handleCheckboxList.bind(this)
    this.handleToggleItems = this.handleToggleItems.bind(this)
    this.handleCheckboxFeatures = this.handleCheckboxFeatures.bind(this)
    this.handleToggleFeatures = this.handleToggleFeatures.bind(this)
    this.handleSelectBenefitType = this.handleSelectBenefitType.bind(this)
    this.handleInputFeature = this.handleInputFeature.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.handleSelectFeatures = this.handleSelectFeatures.bind(this)
    this.checkIsRemunInputDisabled = this.checkIsRemunInputDisabled.bind(this)
    this.checkIsRenewalRemunToUpdate = this.checkIsRenewalRemunToUpdate.bind(this)
    this.state = {
      featureChecked: new Map(),
      updatedDisplayOnChooseCover: displayOnChooseCover,
      updatedRemuneration: remuneration,
      updatedAllowableDefermentPeriodValue: allowableDefermentPeriod,
      updatedAllowableBenefitPeriodValue: allowableBenefitPeriod,
      benefitTypeAllowableFeature: updatedBenefitTypeAllowableFeature || allowableFeatures,
      allBenefitsTypes: this.pivotBenefitType(),
      switchedBenefit,
      updatedInputBenefitType: updatedInputBenefiType,
    }
  }

  switchBenefitByTypeSelection = (currentBenefit, benefitsList, selection: '' | Object) => {
    if (selection) {
      return benefitsList.find(b => b.benefitCode === selection.value)
    }
    return currentBenefit
  }

  settingDropdownOptions = dropDownValues =>
    dropDownValues &&
    dropDownValues
      .map(dropDownValue => ({ ...dropDownValue, label: dropDownValue.displayValue }))
      .filter(dropDownValue => dropDownValue.displayValue !== '')

  handleCheckboxList = (data, value, checked) =>
    data.map(element =>
      element.value === value
        ? {
            ...element,
            selected: checked,
          }
        : element
    )

  handleToggleItems = (data, event) =>
    data.map(element => ({ ...element, selected: element.value === event.value }))

  handleToggleItemsMap = (map, event) => {
    map.forEach(element => (element.selected = element.value === event.value))
    return map
  }

  handleInput = (data, code, value) => {
    data.map(element => (element.code === code ? (element.value = value) : null))
    return data
  }

  handleDropDown = (data, event) => {
    data.map(element =>
      element.displayValue === event.value.displayValue
        ? (element.selected = true)
        : (element.selected = false)
    )
    return data
  }

  handleCheckboxFeatures(event) {
    const { value, checked } = event.target
    const { setFormTouched } = this.props
    const {
      switchedBenefit: { allowableFeatures },
    } = this.state

    const updatedFeatures = this.handleCheckboxList(allowableFeatures, value, checked)
    this.setState(prevState => ({
      featureChecked: prevState.featureChecked.set(value, checked),
    }))
    this.updateSwitchedBenefit('allowableFeatures', updatedFeatures)
    setFormTouched(true)
  }

  handleToggleFeatures(event, code) {
    const { setFormTouched } = this.props
    const {
      switchedBenefit: {
        premiumStructure,
        remuneration,
        remunerationDiscount,
        allowableCoverStyle,
        allowableTpdDefinition,
      },
    } = this.state
    if (code === PREMIUM_STRUCTURE) {
      const updatedPremium = this.handleToggleItems(premiumStructure, event)
      this.updateSwitchedBenefit('premiumStructure', updatedPremium)
    } else if (code === COVER_STYLE) {
      const updatedCoverStyle = this.handleToggleItems(allowableCoverStyle, event)
      this.updateSwitchedBenefit('allowableCoverStyle', updatedCoverStyle)
    } else if (code === TPD_DEFINITION) {
      const updatedTpdDefinition = this.handleToggleItems(allowableTpdDefinition, event)
      this.updateSwitchedBenefit('allowableTpdDefinition', updatedTpdDefinition)
    } else {
      const updatedRemuneration = this.handleToggleItems(remuneration, event)

      // Check if value is 'level', then update the 'Renewal Remuneration'
      // value which is present in 'Initial Remuneration'
      if (event.value.toLowerCase() === RENEWAL_REMUNERATION_LEVEL_VALUE) {
        let initialRemunerationCurrentValue
        // assign current remunerationDiscount value
        let updatedRemunerationDiscount = remunerationDiscount
        // loop through remunerationDiscount
        remunerationDiscount.map(data => {
          // check if code is 'initialRemuneration' then take its
          // current value and update the same in 'renewalRemuneration'
          if (data.code === INITIAL_REMUNERATION_INPUT_CODE) {
            updatedRemunerationDiscount = this.handleInput(
              remunerationDiscount,
              RENEWAL_REMUNERATION_INPUT_CODE,
              data.value
            )
            initialRemunerationCurrentValue = data.value
          }
          return data
        })
        // update 'Remuneration' toggle, update 'RemunerationDiscount' and
        // update 'inputrenewalRemuneration' with 'initialRemuneration' value
        this.updateSwitchedBenefit('remuneration', updatedRemuneration)
        this.setState({
          updatedRemunerationDiscount,
          inputrenewalRemuneration: initialRemunerationCurrentValue,
        })
      } else {
        // update only 'Remuneration' toggle
        this.updateSwitchedBenefit('remuneration', updatedRemuneration)
      }
    }
    setFormTouched(true)
  }

  updateSwitchedBenefit = (key, value) => {
    const { switchedBenefit } = this.state
    this.setState({
      switchedBenefit: {
        ...switchedBenefit,
        [key]: value,
      },
    })
  }

  /**
   * checkIsRenewalRemunToUpdate
   *  check if Remuneration Override toggle is selected as level,
   *  and input code is equal to 'initialRemuneration', then return true
   *
   * @param {string} inputCode
   *
   * @returns {boolean} isRenewalRemunToUpdate
   */
  checkIsRenewalRemunToUpdate(inputCode) {
    let isRenewalRemunToUpdate = false

    const {
      switchedBenefit: { remuneration },
    } = this.state

    // loop through remuneration
    remuneration.map(data => {
      // check if remuneration is selected and remuneration override code
      // is equal to 'level' and user is changing
      // 'initialRemuneration' input then update the same value in 'renewalRemuneration' input
      if (
        data.selected &&
        data.code.toLowerCase() === RENEWAL_REMUNERATION_LEVEL_CODE.toLowerCase() &&
        inputCode === INITIAL_REMUNERATION_INPUT_CODE
      ) {
        isRenewalRemunToUpdate = true
      }
      return data
    })

    return isRenewalRemunToUpdate
  }

  handleInputFeature({ value, name }) {
    let updatedRemunerationDiscount
    const { setFormTouched } = this.props
    if (value <= MAX_RANGE && value >= MIN_RANGE) {
      const {
        switchedBenefit: { remunerationDiscount },
      } = this.state
      updatedRemunerationDiscount = this.handleInput(remunerationDiscount, name, value)

      const isRenewalRemunToUpdate = this.checkIsRenewalRemunToUpdate(name)

      if (isRenewalRemunToUpdate) {
        updatedRemunerationDiscount = this.handleInput(
          remunerationDiscount,
          RENEWAL_REMUNERATION_INPUT_CODE,
          value
        )
        this.updateSwitchedBenefit('remunerationDiscount', updatedRemunerationDiscount)
        this.setState({
          [`input${name}`]: value,
          inputrenewalRemuneration: value,
        })
      } else {
        this.updateSwitchedBenefit('remunerationDiscount', updatedRemunerationDiscount)
        this.setState({
          [`input${name}`]: value,
        })
      }
    }
    setFormTouched(true)
  }

  // handle changes on form elements.
  handleSelectFeatures(event) {
    const { setFormTouched } = this.props
    const {
      switchedBenefit: { allowableBenefitPeriod, allowableDefermentPeriod },
    } = this.state

    if (event.name === DEFERMENT_PERIOD) {
      const allowableDefermentPeriodValue = this.handleDropDown(allowableDefermentPeriod, event)
      this.setState({
        [`input${event.name}`]: event.value,
        allowableDefermentPeriodValue,
      })
    } else {
      const allowableBenefitPeriodValue = this.handleDropDown(allowableBenefitPeriod, event)
      this.setState({
        [`input${event.name}`]: event.value,
        allowableBenefitPeriodValue,
      })
    }
    setFormTouched(true)
  }

  handleClick(event) {
    event.preventDefault()
    const {
      actions: { postAdvisorPreferences, updateAdvisorPreferences },
      productId,
      updatedBenefitTypeAllowableFeature,
      setFormTouched,
      productData,
    } = this.props
    const {
      updatedAllowableDefermentPeriodValue,
      updatedAllowableBenefitPeriodValue,
      updatedDisplayOnChooseCover,
      updatedInputBenefitType,
      benefitTypeAllowableFeature,
      switchedBenefit: {
        premiumStructure,
        allowableFeatures,
        benefitDisplayName,
        benefitCode,
        benefitTypeDisplayName,
        benefitType,
        allowableCoverStyle,
        allowableTpdDefinition,
        remuneration,
        remunerationDiscount,
      },
    } = this.state

    const modifiedFeatures = {
      premiumStructure,
      allowableFeatures: allowableFeatures || updatedBenefitTypeAllowableFeature,
      allowableDefermentPeriod: updatedAllowableDefermentPeriodValue,
      allowableBenefitPeriod: updatedAllowableBenefitPeriodValue,
      remuneration,
      allowableCoverStyle,
      allowableTpdDefinition,
      displayOnChooseCover: updatedDisplayOnChooseCover,
      remunerationDiscount,
    }

    const updatedBenefits = {
      adviserCode: getBancsCustomerNumber(),
      productId,
      benefitDisplayName,
      benefitTypeDisplayName,
      benefitType,
      benefitCode: updatedInputBenefitType ? updatedInputBenefitType.value : benefitCode,
      ...modifiedFeatures,
    }

    const { isError, setInputBenefitType, setBenefitTypeAllowedFeature } = this.props
    updateAdvisorPreferences(updatedBenefits)
    postAdvisorPreferences(updatedBenefits, get(productData, 'length'))

    if (!isError) {
      // display a toast notification which will auto-close.

      setInputBenefitType(updatedInputBenefitType)
      setBenefitTypeAllowedFeature(benefitTypeAllowableFeature)
      setFormTouched(false)
    }
    const tagEvent = createEvent({
      GA: {
        category: 'Quote defaults for each cover type both inside and outside super policies',
        action: 'Edit',
      },
      Splunk: {
        attributes: {
          'workflow.name':
            'Quote defaults for each cover type both inside and outside super policies',
        },
      },
    })
    tagEvent.end()
  }

  /**
   * checkIsRemunInputDisabled
   *  check if Remuneration Override toggle is selected as level,
   *  then return true
   *
   * @returns {boolean} isDisabled
   */
  checkIsRemunInputDisabled() {
    const { updatedRemuneration } = this.state

    let isDisabled = false
    if (updatedRemuneration && Array.isArray(updatedRemuneration)) {
      updatedRemuneration.map(data => {
        // check if current renum is selected and the code is 'level'
        if (
          data.selected &&
          data.code.toLowerCase() === RENEWAL_REMUNERATION_LEVEL_CODE.toLowerCase()
        ) {
          isDisabled = true
        }
        return data
      })
    }

    return isDisabled
  }

  getSelectedBenefitValue(typeCode) {
    const { inputbenefitType } = this.state
    const { updatedInputBenefiType, productBenefitList } = this.props
    if (inputbenefitType) return inputbenefitType
    if (updatedInputBenefiType) return updatedInputBenefiType
    let value
    // for (const i in productBenefitList) {
    //   const benefit = productBenefitList[i]
    //   if (
    //     benefit.benefitType &&
    //     benefit.displayOnChooseCover > 0 &&
    //     benefit.benefitType.code === typeCode.code
    //   ) {
    //     value = benefit.benefitCode
    //     break
    //   }
    // }
    Object.keys(productBenefitList).forEach(key => {
      const benefit = productBenefitList[key]
      if (
        benefit.benefitType &&
        benefit.displayOnChooseCover > 0 &&
        benefit.benefitType.code === typeCode.code
      ) {
        value = benefit.benefitCode
      }
    })
    return value
  }

  createBenefitTypeOptions = (benefitTypeCode): void => {
    const { allBenefitsTypes } = this.state
    const benefitTypes = allBenefitsTypes.get(benefitTypeCode.code)
    return benefitTypes
  }

  getBenefitCode() {
    const {
      inputbenefitType,
      switchedBenefit: { benefitCode },
    } = this.state
    return inputbenefitType ? inputbenefitType.value : benefitCode
  }

  handleSelectBenefitType(event) {
    const { allBenefitsTypes } = this.state
    const { productBenefitList, setFormTouched, benefits } = this.props
    const updatedBenefitType = this.handleToggleItemsMap(allBenefitsTypes, event.value)
    const newSwitchedBenefit = this.switchBenefitByTypeSelection(
      benefits,
      productBenefitList,
      event.value
    )
    const {
      allowableFeatures,
      premiumStructure,
      remuneration,
      remunerationDiscount,
      allowableBenefitPeriod,
      allowableDefermentPeriod,
      allowableCoverStyle,
      allowableTpdDefinition,
      displayOnChooseCover,
    } = newSwitchedBenefit
    this.setState({
      [`input${event.name}`]: event.value,
      updatedBenefitType,
      inputbenefitType: event.value,
      updatedInputBenefitType: event.value,
      benefitTypeAllowableFeature: productBenefitList.find(
        type => type.benefitCode === event.value.value
      ).allowableFeatures,
      switchedBenefit: newSwitchedBenefit,
      updatedFeatures: allowableFeatures,
      updatedPremium: premiumStructure,
      updatedDisplayOnChooseCover: displayOnChooseCover,
      updatedCoverStyle: allowableCoverStyle,
      updatedTpdDefinition: allowableTpdDefinition,
      updatedRemuneration: remuneration,
      updatedRemunerationDiscount: remunerationDiscount,
      updatedAllowableDefermentPeriodValue: allowableDefermentPeriod,
      updatedAllowableBenefitPeriodValue: allowableBenefitPeriod,
    })
    setFormTouched(true)
  }

  pivotBenefitType() {
    const allBenefitsTypes = new Map()
    const { productBenefitList } = this.props

    productBenefitList.map(benefit => {
      if (benefit.benefitType) {
        if (
          benefit &&
          (allBenefitsTypes.size === 0 || !allBenefitsTypes.get(benefit.benefitType.code))
        ) {
          allBenefitsTypes.set(benefit.benefitType.code, [
            {
              value: benefit.benefitCode,
              label: benefit.benefitTypeDisplayName,
              selected: benefit.displayOnChooseCover === 1,
            },
          ])
        } else {
          allBenefitsTypes.get(benefit.benefitType.code).push({
            value: benefit.benefitCode,
            label: benefit.benefitTypeDisplayName,
            selected: benefit.displayOnChooseCover === 1,
          })
        }
      }
    })
    return allBenefitsTypes
  }

  getSelectedTpdDefinition = productId => {
    const { switchedBenefit } = this.state
    const { allowableTpdDefinition } = switchedBenefit

    return allowableTpdDefinition.map(structure => ({
      ...structure,
      disabled:
        !isNonSuperProduct(productId) &&
        structure.code.toLowerCase() === TPD_DEFINITION_OWN.toLowerCase(),
    }))
  }

  render() {
    const { productId, fields, isPreferencePostLoading, isCaliRelabel } = this.props
    const { featureChecked, switchedBenefit } = this.state
    const {
      allowableFeatures,
      premiumStructure,
      allowableDefermentPeriod,
      allowableBenefitPeriod,
      remuneration,
      benefitCode,
      benefitDisplayName,
      benefitType,
      remunerationDiscount,
      allowableCoverStyle,
      allowableTpdDefinition,
    } = switchedBenefit
    const {
      overrides,
      commission,
      benefitTypeLabel,
      coverOption,
      coverStyle,
      tpdDefinition,
      benefitPeriod,
      waitingPeriod,
      confirm,
      overridesStructure,
    } = fields
    const isRenumInputDisabled = this.checkIsRemunInputDisabled()
    const features =
      (Array.isArray(allowableFeatures) && allowableFeatures.length && allowableFeatures) || []
    return (
      <Container>
        <Form id="advisorPreferences">
          {allowableCoverStyle && allowableCoverStyle.length > 0 && (
            <ContainerToggleGroup>
              <Heading size="6"> {coverStyle}</Heading>
              <Text>{allowableCoverStyle[0]?.value}</Text>
            </ContainerToggleGroup>
          )}
          {allowableTpdDefinition &&
            !!allowableTpdDefinition.length &&
            allowableTpdDefinition.length !== 1 && (
              <ContainerToggleGroup>
                <Heading size="6"> {tpdDefinition}</Heading>
                <FullWidthToggleGroup
                  htmlFor={`allowableTpdDefinition-${benefitCode}`}
                  label=""
                  name={`allowableTpdDefinition-${benefitCode}`}
                  value={`allowableTpdDefinition-${benefitCode}`}
                  labelledby={`allowableTpdDefinition-${benefitCode}`}
                  className="itemWidth"
                  options={this.getSelectedTpdDefinition(productId)}
                  handleChange={event => this.handleToggleFeatures(event, 'tpdDefinition')}
                />
              </ContainerToggleGroup>
            )}
          {benefitType && (
            <div>
              <div>
                <FullWidthSelect
                  id="benefitType"
                  label={benefitTypeLabel}
                  name="benefitType"
                  value={benefitType ? this.getSelectedBenefitValue(benefitType) : ''}
                  options={this.createBenefitTypeOptions(benefitType)}
                  changeHandler={event => this.handleSelectBenefitType(event)}
                />
              </div>
            </div>
          )}
          {features && features.length > 0 && (
            <div>
              <Title size="6">
                {benefitDisplayName} {coverOption}
              </Title>
              <div id={`allowableFeatures-${benefitCode}`}>
                {features &&
                  features.map((item, index) => (
                    <InnerHolder key={`allowableFeature-${index}`}>
                      <Checkbox
                        text={item.value}
                        name={`allowableFeatures-${benefitCode}`}
                        htmlFor={`allowableFeatures-${benefitCode}-${index}`}
                        disabled={false}
                        value={item.value}
                        variant="multiselect"
                        checked={item.selected || featureChecked.get(item.value)}
                        onChangeHandler={this.handleCheckboxFeatures}
                      />
                    </InnerHolder>
                  ))}
              </div>
            </div>
          )}
          {premiumStructure && premiumStructure.length > 0 && (
            <>
              <Heading size="6">{commission}</Heading>
              <Text>
                {isCaliRelabel
                  ? premiumStructure.find(p => p?.code === 'Stepped') && NEW_STEPPED_LABEL
                  : premiumStructure.find(p => p?.code === 'Stepped')?.value}
              </Text>
            </>
          )}
          {remuneration && remuneration.length > 0 && (
            <ContainerToggleGroup>
              <Heading size="6"> {overrides}</Heading>
              <FullWidthToggleGroup
                htmlFor={`remuneration-${benefitCode}`}
                label={overridesStructure}
                name={`remuneration-${benefitCode}`}
                labelledby={`remuneration-${benefitCode}`}
                gutter="none"
                options={remuneration}
                handleChange={event => this.handleToggleFeatures(event, 'remuneration')}
              />
            </ContainerToggleGroup>
          )}
          {remunerationDiscount &&
            remunerationDiscount.map((renum, index) => (
              <div key={index}>
                {renum.code === READONLY ? (
                  <div>
                    <Label htmlFor={renum.label} id={`${renum.label}-label`}>
                      {renum.label}
                    </Label>
                    <PanelLabel>{renum.value}</PanelLabel>
                  </div>
                ) : (
                  <Input
                    pattern="[0-9]*"
                    disabled={
                      renum.code === RENEWAL_REMUNERATION_INPUT_CODE && isRenumInputDisabled
                    }
                    key={`renum-${index}`}
                    htmlFor={`renum-${index}`}
                    label={renum.label}
                    name={renum.code}
                    type="number"
                    value={renum.value}
                    changeHandler={event => this.handleInputFeature(event)}
                  />
                )}
              </div>
            ))}
          {allowableDefermentPeriod && allowableDefermentPeriod.length > 0 && (
            <div>
              <Heading size="6"> {waitingPeriod}</Heading>
              {allowableDefermentPeriod && (
                <FullWidthSelect
                  value={getSelectedValue(allowableDefermentPeriod).value || ''}
                  name="defermentPeriod"
                  id="defermentPeriod"
                  options={this.settingDropdownOptions(allowableDefermentPeriod)}
                  changeHandler={event => this.handleSelectFeatures(event)}
                />
              )}
            </div>
          )}
          {allowableBenefitPeriod && allowableBenefitPeriod.length > 0 && (
            <div>
              <Heading size="6"> {benefitPeriod}</Heading>
              {allowableBenefitPeriod && (
                <FullWidthSelect
                  value={getSelectedValue(allowableBenefitPeriod).value || ''}
                  name="benefitPeriod"
                  id="benefitPeriod"
                  options={this.settingDropdownOptions(allowableBenefitPeriod)}
                  changeHandler={event => this.handleSelectFeatures(event)}
                />
              )}
            </div>
          )}
          <Footer>
            <Button type="secondary" onClick={this.handleClick} isLoading={isPreferencePostLoading}>
              {confirm}
            </Button>
          </Footer>
        </Form>
      </Container>
    )
  }
}

export const mapStateToProps = ({ productRules, config }) => ({
  productData: productRules.data,
  isCaliRelabel: isFeatureEnabled('CaliRelabel', config.FEATURES),
})

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(Panels)
