import styled from '@emotion/styled'
import { Button, Heading, Icons } from '@mlcl-digital/mlcl-design'
import { RichText } from '@sitecore-jss/sitecore-jss-react'
import React from 'react'

// redux.
import { useSelector } from 'react-redux'

// components.
// @ts-expect-error file not in typescript
import Dropzone from '../../../../../molecules/FileUpload/components/Dropzone'
// @ts-expect-error file not in typescript
import FileUploadingProgress from '../../../../../molecules/FileUpload/components/FileUploadingProgress'

// utils
// @ts-expect-error file not in typescript
import { actionCreators } from '../../../../../../actions'
import {
  generateFileKey,
  // @ts-expect-error file not in typescript
} from '../../../../../../utils/fileUpload'

// types
import { fieldsType } from '../../../../../../types/components/SupportStaffRegisterForm'
import { Store } from '../../../../../../types/store'
import { File } from '../../../../../../types/Document'

// styles
import styles from './supportStaffBulkUpload.styles'

type SupportStaffRegisterFormProps = {
  // To render all labels from sitecore content editor
  fields: fieldsType
  originalFields: Record<string, object | object[]>
  handleBack: (setShowRegisterForm: string) => void
}

type instructionValueType = { Instruction: { value: string } }
type instructionType = {
  fields: instructionValueType
}

const SupportStaffBulkUploadWrapper = styled('section')(styles.supportStaffBulkUploadWrapper)
const BulkUploadDescription = styled('p')(styles.bulkUploadDescription)
const NavButton = styled(Button)(styles.nav)
const BulkUploadInstructions = styled('ol')(styles.bulkUploadInstructions)
const ListItem = styled('li')(styles.listItem)
const CSVDropzoneWrapper = styled('div')(styles.uploadCertificateWrapper)
const { IconChevronLeft16 } = Icons as { [key: string]: React.ReactNode }
const Footer = styled('footer')(styles.footer)
const UploadBtn = styled(Button)(styles.uploadBtn)

export const renderFileList = (
  fields: fieldsType,
  files: File[],
  handleFileRemove: (filedId: string) => void,
  validateFiles: (list: File[]) => Record<string, string | boolean | File>[]
) => {
  const {
    adviserRegistrationFileUploading,
    adviserRegistrationUploadSuccess,
    adviserRegistrationUploadFailure,
    adviserRegistrationFileRemove,
  } = fields
  const fileUploadMeta = {
    fileUploading: adviserRegistrationFileUploading,
    fileUploadSuccess: adviserRegistrationUploadSuccess,
    fileUploadFailure: adviserRegistrationUploadFailure,
    fileRemoveButtonLabel: adviserRegistrationFileRemove,
  }

  return (
    <>
      {files.map(fileData => {
        const fileKey = generateFileKey(fileData)
        return (
          <FileUploadingProgress
            key={fileKey}
            handleFileRemove={handleFileRemove}
            fileUploadMeta={fileUploadMeta}
            validateFiles={validateFiles}
            fileData={fileData}
          />
        )
      })}
    </>
  )
}

const supportStaffBulkUpload = (props: SupportStaffRegisterFormProps) => {
  const { fields, handleBack, originalFields } = props
  const { browseFilesToUpload, removeFile } = actionCreators
  const files = useSelector((state: Store) => state.fileUploadInfo.files)

  const validateFiles = (list: File[]) => {
    let result: Record<string, boolean | string | File> = { hasValidationError: false }

    const data = Object.values(list).map(file => {
      const fileId = generateFileKey(file)
      result = { ...result, file, fileId }

      return result
    })

    // eslint-disable-next-line consistent-return
    return data
  }

  const handleFileRemove = (filedId: string) => {
    if (filedId !== '') {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      removeFile(files, filedId)
    }
  }

  const handleFileDrop = (file: File) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    browseFilesToUpload(file)
  }

  const renderInstructionList = (bulkUploadInstructionList: instructionType[]) =>
    bulkUploadInstructionList.map(listItem => (
      <ListItem>
        <RichText field={{ value: Object.values(listItem.fields)[0].value }} />
      </ListItem>
    ))

  return (
    <SupportStaffBulkUploadWrapper>
      <NavButton
        data-testid="backButton"
        onClick={() => handleBack('SupportStaffListSection')}
        variant="tertiary"
      >
        {/* @ts-expect-error non-TS-code  */}
        <IconChevronLeft16 />
        {fields.backButtonLabel}
      </NavButton>
      <Heading variant="h2" size="large" aria-label={fields.supportStaffRegisterFieldSetAreaLabel}>
        {fields.BulkUploadHeading}
      </Heading>
      <BulkUploadDescription>
        <RichText field={{ value: fields.BulkUploadDescription }} />
      </BulkUploadDescription>
      <BulkUploadInstructions>
        {renderInstructionList(originalFields.BulkUploadInstructionList as instructionType[])}
      </BulkUploadInstructions>
      <CSVDropzoneWrapper>
        <Dropzone
          hasMultipleFiles={false}
          handleFileDrop={handleFileDrop}
          isFileUploading={files?.length}
          bulkUploadIcon
        />
        {files && files.length
          ? renderFileList(fields, files, handleFileRemove, validateFiles)
          : null}
      </CSVDropzoneWrapper>
      <Footer>
        <UploadBtn type="secondary" disabled>
          {fields.AddMultipleUploadButtonLabel}
        </UploadBtn>
      </Footer>
    </SupportStaffBulkUploadWrapper>
  )
}

export default supportStaffBulkUpload
