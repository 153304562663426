// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Variables } from '@mlcl-digital/mlcl-design'
import { VariablesType } from '../../../../../../types/ComponentLibrary'

const { colours, space, fontSize } = Variables as VariablesType

const styles = {
  footer: {
    padding: `${space(15, true)} 0 ${space(7, true)} 0`,
  },
  bulkUploadDescription: { color: colours.tertiaryOne },
  supportStaffBulkUploadWrapper: {
    width: '100%',
    paddingLeft: space(12),
    '& label': {
      marginBottom: space(1),
    },
    flexBasis: 'calc(85% - 9px)',
  } as const,
  nav: {
    display: 'flex',
    justifyContent: 'flex-start',
    fontSize: fontSize.xs,
    padding: '17px 17px 22px 0',
    height: 'auto',
    color: colours.mlcAquaAccessible,
    svg: {
      marginRight: space(0.5),
    },
    '&:hover': {
      color: colours.mlcAquaAccessible,
      textDecoration: 'none',
    },
    textDecoration: 'underline',
  },
  uploadBtn: {
    fontSize: '18px',
  },
  bulkUploadInstructions: {
    '& > table, th, td': {
      // TODO: refer to lib color once confirming with UX team
      border: '1px solid #B9B9B9',
      fontSize: '12px',
    },
    '& th, td': {
      padding: '5px 20px',
    },
    th: {
      // TODO: refer to lib color once confirming with UX team
      backgroundColor: '#0000000F',
    },
  },
  listItem: {},
  uploadCertificateWrapper: {
    marginTop: '44px',
    '& > div': {
      flexDirection: 'column',
      gap: '16px',
      padding: '16px 0 32px',
    },
    button: {
      height: '24px',
    },
  } as const,
}

export default styles
